/**
 * Provider
 * See: https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
 */
import Provider from "./src/context/Provider"

/**
 * Tailwind
 */

/**
 * Fonts
 */
import "./src/styles/tailwind.css"

/**
 * Removing the no-js class for body
 * useful with e.g. FadeInBox and FadeInUpBox
 * See: https://curtistimson.co.uk/post/gatsbyjs/add-body-class-gatsbyjs-fouc/
 */

export const onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
}

export const wrapRootElement = Provider
