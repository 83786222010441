// import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const SiteContext = React.createContext()

const Provider = props => {
  const test = 1

  return (
    <SiteContext.Provider
      value={{
        test,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>
